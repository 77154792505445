/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { Button } from '../components/atoms';
import { DefaultLayout } from '../components/layout';
import { CaseTeaser, Stage, Teaser } from '../components/molecules';
import { TeaserList } from '../components/organisms';
import { replaceCount } from '../utils/count';

const teasers = [
  {
    title: 'Innovativ',
    subline: 'Zielführend',
    description:
      'Mit unseren zielorientierten und innovativen Lösungsangebot erreichen wir gemeinsam die gewünschten Ergebnisse für Ihr Unternehmen.',
    link: '/leistungen',
    linkText: 'Entdecken Sie unsere Leistungen',
  },
  {
    title: 'Kompetent',
    subline: 'Ein Projekt, ein Team',
    description:
      'Mit uns haben Sie immer einen kompetenten Ansprechpartner, der Ihnen in allen Belangen zur Seite steht und sicherstellt, dass Ihr Projekt erfolgreich umgesetzt wird.',
    link: '/projekte',
    linkText: 'Erfahren Sie mehr über unsere Projekte',
  },
  {
    title: 'Kommunikation',
    subline: 'Unser Leitfaden',
    description:
      'Schnelle und effektive Problemlösung durch direkte Kommunikation mit unserem Team bei der DeepReturn GmbH. Wir sind für Sie da und freuen uns auf Ihre Anfrage.',
    link: '/kontakt',
    linkText: 'Lernen Sie uns kennen',
  },
];

const pageQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "Index" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
          link
          linkText
        }
      }
    }
    allImageSharp(filter: { fluid: { originalName: { regex: "/example_nodes.png/" } } }) {
      edges {
        node {
          id
          fluid(maxWidth: 800, quality: 92) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

const Index = (location) => (
  <StaticQuery
    query={pageQuery}
    render={({ allStagesJson, allImageSharp }) => {
      const { imageSrc, imageAlt, title, contentBlocks, link, linkText } = allStagesJson.edges[0].node;
      const caseImage = allImageSharp.edges[0].node.fluid;
      return (
        <DefaultLayout
          siteTitle="Softwareentwicklung und KI-Lösungen | DeepReturn"
          siteDescription={
            'DeepReturn bietet massgeschneiderte Softwareentwicklung und KI-Lösungen für Unternehmen. ' +
            'Entdecken Sie unsere innovativen Projekte und erfahren Sie, wie wir Ihr Unternehmen unterstützen können.'
          }
          location={location}
        >
          <Stage
            modifiers={['landing-page', 'left-highlighted']}
            image={{
              fluid: imageSrc.childImageSharp.fluid,
              alt: imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>}
            >
            {contentBlocks.map(({ id, value }) => (
              <p key={id}>{replaceCount(value, 2)}</p>
            ))}
            <Button url={link} text={linkText} isPrimary />
          </Stage>
          <TeaserList>
            {teasers.map((teaser) => (
              <Teaser key={teaser.title} title={teaser.title} subline={teaser.subline}>
                <p>{teaser.description}</p>
                <Button url={teaser.link} text={teaser.linkText} isPrimary hasBorder />
              </Teaser>
            ))}
          </TeaserList>
          <CaseTeaser
            modifiers={['right-highlighted', 'image-padded']}
            url="https://deepconnect.deepreturn.com/"
            title="DeepConnect"
            subline="Ideale Lösung für KMU"
            image={{
              fluid: caseImage,
              alt: 'Archiv',
            }}
            allProjects
          >
            <p>
              {`DeepConnect ist immer dann hilfreich, wenn Sie Formatierungen an einer CSV oder XML Datei vornehmen wollen. `}
              {`Mithilfe von DeepConnect können Sie selbständig Automatisierungen erstellen, `}
              {`ohne dazu auch nur eine Linie Code schreiben zu müssen.`}
            </p>
          </CaseTeaser>
        </DefaultLayout>
      );
    }}
  />
);

export default Index;
